<!-- Componente utilizado para montagem de gráficos. Foi implementado em alguma fase do projeto. -->

<script>
import { Line } from 'vue-chartjs'
import EyeFill from '@/assets/icons/eye-fill.svg'

export default {
  extends: Line,
  props: ['data', 'options'],
  components: {
    EyeFill
  },
  mounted () {
    this.blueGradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    this.blueGradient.addColorStop(0, 'rgba(48, 91, 242, 0.1)')
    this.blueGradient.addColorStop(1, 'rgba(48, 91, 242, 0)')

    this.orangeGradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    this.orangeGradient.addColorStop(0, 'rgba(255, 107, 0, 0.1)')
    this.orangeGradient.addColorStop(1, 'rgba(255, 107, 0, 0)')

    const dataWithGradient = this.data
    dataWithGradient.datasets = dataWithGradient.datasets.map(dataset => {
      if (dataset.backgroundColor === '#FF6B00') {
        dataset.backgroundColor = this.orangeGradient
      } else if (dataset.backgroundColor === '#305BF2') {
        dataset.backgroundColor = this.blueGradient
      }

      return dataset
    })

    const options = this.options
    
    this.renderChart(dataWithGradient, options)
  }
}
</script>

<style type="text/scss" scoped>
  #chartjs-tooltip {
    width: 177px;
    height: 56px;
    background-color: var(--blue-700);
    border-radius: 4px;
    padding: 8px 8px 0 8px !important;

    font-weight: 700;
    font-size: 12px;
    color: var(--neutral-000);
  }

  #chartjs-tooltip span {
      font-weight: 400;
  }

  .tag-eye {
    width: 32px;
    height: 16px;
    background-color: var(--neutral-200);
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--neutral-600);
    margin-right: 6px;
  }

  .tag-eye svg {
      width: 10px;
      height: 10px;
      fill: var(--neutral-600);
      margin-right: 4px;
  }
</style>
